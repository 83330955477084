import {
  AbstractedFetchFunction,
  useLazyQuery,
} from '@collinsonx/utils/apollo';
import { Entitlement } from '@collinsonx/utils/generatedTypes/graphql';
import { getEntitlementsForProduct } from '@collinsonx/utils/queries';
import { BookingContext } from 'context/bookingContext';
import { useContext } from 'react';

import usePayload from './payload';
import useProduct from './useProduct';

export type FetchEntitlementsData = {
  getEntitlementsForProduct: Entitlement[];
};

export type FetchEntitlementsType =
  AbstractedFetchFunction<FetchEntitlementsData>;

interface UseEntitlements {
  entitlementsData?: FetchEntitlementsData;
  fetchEntitlements: FetchEntitlementsType;
  loadingEntitlements: boolean;
}

const useEntitlements = (): UseEntitlements => {
  const { productID } = useProduct();
  const { linkedAccountId, payload } = usePayload();
  const { booking } = useContext(BookingContext);

  const accountProvider = payload?.accountProvider;
  const programme = linkedAccountId ? accountProvider : null;
  const membershipIDOrLinkedAccountID =
    payload?.membershipId || linkedAccountId;
  const { departureDate } = booking || {};

  const [
    fetchEntitlements,
    { data: entitlementsData, loading: loadingEntitlements },
  ] = useLazyQuery<{
    getEntitlementsForProduct: Entitlement[];
  }>(getEntitlementsForProduct, {
    variables: {
      bookingEnd: departureDate,
      membershipID: membershipIDOrLinkedAccountID,
      productId: productID,
      programme,
      retry: false,
    },
  });

  return { entitlementsData, fetchEntitlements, loadingEntitlements };
};

export default useEntitlements;
