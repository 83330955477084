import {
  ActionIcon,
  Box,
  Flex,
  Group,
  NumberInput,
  NumberInputHandlers,
  Text,
} from '@collinsonx/design-system/core';
import clsx from 'clsx';
import React, { useRef } from 'react';

import classes from './QuantityInput.module.css';

interface QuantityInputProps {
  disabled?: boolean;
  label?: string;
  max?: number;
  min?: number;
  onChange: (val: number | string) => void;
  subText?: string;
  value: '' | number;
}

const QuantityInput: React.FC<QuantityInputProps> = ({
  disabled = false,
  label,
  max = Infinity,
  min = 0,
  onChange,
  subText,
  value,
}) => {
  const inputHandler = useRef<NumberInputHandlers>();

  return (
    <Flex align="center" direction="row" wrap="wrap">
      {(label || subText) && (
        <Box w={85}>
          {label && (
            <Text fw={600} size="lg">
              {label}
            </Text>
          )}
          {subText && <Text size="xs">{subText}</Text>}
        </Box>
      )}
      <Group gap={0}>
        <ActionIcon
          aria-label={`Decrement ${label}`}
          className={clsx([
            classes.icon,
            classes.iconLeft,
            { [classes.iconDisabled]: disabled || Number(value) <= min },
          ])}
          disabled={disabled || Number(value) <= min}
          h={50}
          onClick={() => inputHandler.current?.decrement()}
          size="2.625rem"
          variant="default"
        >
          –
        </ActionIcon>
        <NumberInput
          aria-label={`Selected ${label}`}
          classNames={{
            input: classes.numberInput,
            wrapper: classes.numberInputRoot,
          }}
          disabled={disabled}
          handlersRef={inputHandler}
          hideControls
          max={max}
          min={min}
          onChange={(val) => onChange(val)}
          readOnly
          size="md"
          step={1}
          value={value}
          w={80}
        />
        <ActionIcon
          aria-label={`Increment ${label}`}
          className={clsx([
            classes.icon,
            classes.iconRight,
            { [classes.iconDisabled]: disabled || Number(value) >= max },
          ])}
          disabled={disabled || Number(value) >= max}
          h={50}
          onClick={() => inputHandler.current?.increment()}
          size="2.625rem"
          variant="default"
        >
          +
        </ActionIcon>
      </Group>
    </Flex>
  );
};

export default QuantityInput;
