import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Text } from '@collinsonx/design-system/core';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import LazyLoad from '@components/LazyLoad/LazyLoad';
import { getItem } from '@lib';
import { BOOKING_MODE } from 'config/constants';
import { BOKING_MODE_STATE } from 'config/storage';
import { BookingContext } from 'context/bookingContext';
import useLocale from 'hooks/useLocale';
import usePrice from 'hooks/usePrice';
import { useContext } from 'react';
import { replaceTranslationPlaceholder } from 'utils/translations';

import classes from '../../styles/BlockWrapper.module.css';

interface TotalPriceBlockProps {
  border?: boolean;
  guestsCount?: number;
  showUsedEntitlements?: boolean;
  usedEntitlementsCount?: number;
}

export default function TotalPriceBlock({
  border = true,
  guestsCount,
  showUsedEntitlements = false,
  usedEntitlementsCount,
}: TotalPriceBlockProps = {}) {
  const { booking } = useContext(BookingContext);
  const mode = getItem(BOKING_MODE_STATE);
  const translations = useLocale();

  const {
    priceIsAmended,
    selectedEntitlementsCount: selectedEntitlementsCountFromBooking,
    taxableGuestsCount,
  } = booking;

  const entitlementsCount =
    usedEntitlementsCount ?? selectedEntitlementsCountFromBooking ?? 0;

  const { loadingPrice, totalPriceFormatted } = usePrice({
    entitlementsCount,
    guestsCount: guestsCount ?? taxableGuestsCount,
  });

  return (
    <BlockWrapper border={border}>
      <LazyLoad loading={loadingPrice}>
        <div>
          {!priceIsAmended && mode === BOOKING_MODE.EDIT ? (
            <div>
              <Heading as="h2" margin={0} padding={0}>
                {translations.booking.availableSlots.totalPrice.titleAmend}
              </Heading>
              <p>{translations.booking.availableSlots.totalPrice.samePrice}</p>
            </div>
          ) : (
            <div>
              <Heading as="h2" margin={0} padding={0}>
                {translations.booking.availableSlots.totalPrice.title}
              </Heading>
              <Text style={{ marginTop: '16px' }}>{totalPriceFormatted}</Text>
            </div>
          )}
        </div>
      </LazyLoad>
      {showUsedEntitlements && entitlementsCount > 0 && (
        <div className={classes.entitlementsBlock}>
          <span>{usedEntitlementsCount} x </span>
          <span>
            {replaceTranslationPlaceholder(
              translations.booking.confirmationPayment.outcome.succesful
                .entitlementsRedeemed,
              {
                plural: entitlementsCount > 1 ? 's' : '',
              }
            )}
          </span>
        </div>
      )}
    </BlockWrapper>
  );
}
