import { DATE_TIME_FORMAT } from 'config/constants';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { safe } from './safe';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatDate(
  dateval: Date,
  formattype: string,
  locale?: string
): string {
  return safe(() => {
    dayjs.locale(locale || 'en');
    return dayjs(dateval).format(formattype);
  });
}

export function formatTimezone(localTimeHour: string, timezone: string) {
  return safe(() => dayjs.tz(localTimeHour, timezone).format(DATE_TIME_FORMAT));
}
