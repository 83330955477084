import {
  Flex,
  Image,
  Skeleton,
  Text,
  Title,
} from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';
import useProduct from 'hooks/useProduct';
import React, { useMemo } from 'react';
import { getCurrencySymbol } from 'utils/currencysymbol';

import classes from './LoungeInfo.module.css';

interface LoungeInfoProps {
  hideImage?: boolean;
  hideImageMobile?: boolean;
  width?: string;
}

export const LoungeInfo = ({
  hideImage = false,
  hideImageMobile,
  width = '100%',
}: LoungeInfoProps) => {
  const {
    airportName,
    currency,
    imageUrl,
    loadingProduct,
    loungeName,
    saleprice,
    terminal,
  } = useProduct();

  const loungeLocation = useMemo(() => {
    if (!airportName) return '';

    return `${airportName}${terminal ? `, ${terminal}` : ''}`;
  }, [airportName, terminal]);

  const translation = useLocale();

  return (
    <Flex
      align="center"
      bg="#FFF"
      className={classes.container}
      direction={{ base: 'column', xl: 'row' }}
      gap={16}
      justify="center"
      p={24}
      w={width}
    >
      <div>
        <Skeleton visible={loadingProduct} width="100%">
          {!hideImage && imageUrl && (
            <Image
              alt="lounge image"
              className={classes.image}
              src={imageUrl}
              visibleFrom={hideImageMobile ? 'sm' : undefined}
            />
          )}
        </Skeleton>
      </div>
      <Flex
        className={classes.centerMobile}
        direction="column"
        gap={loadingProduct ? 16 : undefined}
        w="100%"
      >
        <Skeleton visible={loadingProduct}>
          <Title data-testid="loungeName" fw={600} order={2} size={32}>
            {loungeName ?? '-'}
          </Title>
        </Skeleton>
        <Skeleton visible={loadingProduct}>
          <Text size="lg">{loungeLocation}</Text>
        </Skeleton>
        <Skeleton visible={loadingProduct}>
          {saleprice && (
            <Flex className={classes.containerPrice} gap={2}>
              <Text className={classes.textPrice} fw={700}>
                {getCurrencySymbol(currency ?? '')}
                {parseFloat(saleprice.toString()).toFixed(2)}
              </Text>
              <Text size="xl" style={{ lineHeight: '50px' }}>
                {translation.lounge.perPerson}
              </Text>
            </Flex>
          )}
        </Skeleton>
      </Flex>
    </Flex>
  );
};
