import { GUEST_TYPES } from 'config/productDefaultConfig';
import { useMemo } from 'react';
import { MappedProductData } from 'types/mappedProductData';
import {
  getExcludedGuestFromPartyCountTranslation,
  getMaxGuestCount,
  partyImpactingGuestsSelected,
  SelectedGuestsCountType,
} from 'utils/product';

import useLocale from './useLocale';
import useProduct from './useProduct';

export const getAvailableGuestTypes = (
  guests: MappedProductData['guests']
) => ({
  [GUEST_TYPES.adults]: Boolean(guests[GUEST_TYPES.adults]),
  [GUEST_TYPES.children]: Boolean(guests[GUEST_TYPES.children]),
  [GUEST_TYPES.infants]: Boolean(guests[GUEST_TYPES.infants]),
  [GUEST_TYPES.seniors]: Boolean(guests[GUEST_TYPES.seniors]),
});

const useGuestDetails = () => {
  const translations = useLocale();
  const { guests, maxPartySize } = useProduct();

  const getPartyParameters = (selectedGuestsCount: SelectedGuestsCountType) =>
    useMemo(() => {
      const getMaxGuestCountOptions = {
        guests,
        maxPartySize,
        selectedGuestsCount,
      };

      const maxAdultsCount = getMaxGuestCount(
        GUEST_TYPES.adults,
        getMaxGuestCountOptions
      );
      const maxChildrenCount = getMaxGuestCount(
        GUEST_TYPES.children,
        getMaxGuestCountOptions
      );
      const maxInfantsCount = getMaxGuestCount(
        GUEST_TYPES.infants,
        getMaxGuestCountOptions
      );
      const maxSeniorsCount = getMaxGuestCount(
        GUEST_TYPES.seniors,
        getMaxGuestCountOptions
      );

      const partySizeReached =
        maxPartySize ===
        partyImpactingGuestsSelected(selectedGuestsCount, guests);

      return {
        maxAdultsCount,
        maxChildrenCount,
        maxInfantsCount,
        maxSeniorsCount,
        partySizeReached,
      };
    }, [selectedGuestsCount, guests, maxPartySize]);

  const availableGuestTypes = getAvailableGuestTypes(guests);
  const excludedGuestFromPartyCountString =
    getExcludedGuestFromPartyCountTranslation(guests, translations);

  return {
    availableGuestTypes,
    excludedGuestFromPartyCountString,
    getPartyParameters,
  };
};

export default useGuestDetails;
